import { render, staticRenderFns } from "./uploadImg.vue?vue&type=template&id=7ef87341&scoped=true&"
import script from "./uploadImg.vue?vue&type=script&lang=ts&"
export * from "./uploadImg.vue?vue&type=script&lang=ts&"
import style0 from "./uploadImg.vue?vue&type=style&index=0&id=7ef87341&prod&lang=scss&scoped=true&"
import style1 from "./uploadImg.vue?vue&type=style&index=1&id=7ef87341&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ef87341",
  null
  
)

export default component.exports