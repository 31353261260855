
import { PropSync, Model, Watch, Prop, Component, Vue } from "vue-property-decorator";

import config from "@/config/default";
import { watch } from "fs";


@Component
export default class UploadImg extends Vue {
    @PropSync("fileList",  { default: () => ([]) }) 
    tempFileList!: any;
    @Prop({ default: 0.5 }) size;
    @Prop({ default: () => ([]) }) fileTypeList;
    @Prop() limit;
    @Prop({ default: `${config.uploadImg}permutation` }) uploadImageUrl;

    dialogVisible = false;
    dialogImageUrl = "";


    handleAvatarSuccess(res, file) {
        console.log(res, file)
        this.tempFileList = [...this.tempFileList, { url: `${config.uploadImgUrl}${res.data.url}` }]
        // console.log(this.tempFileList)
    }

    beforeAvatarUpload(file) {
        const isJPG = ['image/png', 'image/jpeg'].includes(file.type);
        const isLtM = this.size ? (file.size / 1024 / 1024) <= this.size : true;
        if (!isJPG) {
            this.$message.error('图片只能是jpg/png格式!');
        }
        if (!isLtM) {
            this.$message.error(`图片大小不能超过${this.size}M!`);
        }
        return isJPG && isLtM;
    }

    handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
    }
    handleRemove(file, fileList) {
        this.tempFileList = fileList;
    }

    @Watch("tempFileList")
    setDefaultImgList(val) {
        console.log("setDefaultImgList", val)
        if (!val) {
            this.tempFileList = []
            return;
        }
        if (typeof val == "string") {
            this.tempFileList = [{url: val}];
        }
    }
}

